import { createContext } from 'react'

const PhoneDataContext = createContext({})

const PhoneDataProvider = ({ children, phoneData, phoneOverride, associatedProduct }) => {
  if (phoneData) {
    phoneData = {
      phoneNumber: phoneData?.phoneNumbers[associatedProduct],
      phoneCopy: phoneData?.phoneCopy[associatedProduct],
    }
  }

  if (phoneOverride) {
    phoneData = {
      phoneNumber: phoneOverride,
    }
  }
  return <PhoneDataContext.Provider value={phoneData}>{children}</PhoneDataContext.Provider>
}

export { PhoneDataProvider, PhoneDataContext }
