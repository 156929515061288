const SITE_THEME = {
  ACHIEVE: 'achieve',
  FDR: 'website-fdr',
  FFN: 'website-ffn',
  WFS: 'website-wfs',
  DEFAULT: 'achieve',
}

export { SITE_THEME }
export default SITE_THEME
