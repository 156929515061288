import localFont from 'next/font/local'

const ultramarine = localFont({
  src: [
    {
      path: '../../../public/multi-site-assets/fonts/Ultramarine-Bold.woff2',
      weight: '700',
      style: 'normal',
    },
    {
      path: '../../../public/multi-site-assets/fonts/Ultramarine-BoldItalic.woff2',
      weight: '700',
      style: 'italic',
    },
    {
      path: '../../../public/multi-site-assets/fonts/Ultramarine-Italic.woff2',
      weight: '400',
      style: 'italic',
    },
    {
      path: '../../../public/multi-site-assets/fonts/Ultramarine-Medium.woff2',
      weight: '500',
      style: 'normal',
    },
    {
      path: '../../../public/multi-site-assets/fonts/Ultramarine-MediumItalic.woff2',
      weight: '500',
      style: 'italic',
    },
    {
      path: '../../../public/multi-site-assets/fonts/Ultramarine-Regular.woff2',
      weight: '400',
      style: 'normal',
    },
  ],
  style: ['normal', 'italic'],
  preload: true,
  variable: '--font-ultramarine',
  display: 'swap',
})

const withNextFontUltramarine = (ascendTheme) => {
  let updated = {
    ...ascendTheme,
    ascend: {
      ...ascendTheme.ascend,
      typography: {
        ...ascendTheme.ascend.typography,
        fontFamily: ultramarine.style.fontFamily,
      },
    },
    typography: {
      ...ascendTheme.typography,
      fontFamily: ultramarine.style.fontFamily,
    },
  }
  return updated
}

export { ultramarine, withNextFontUltramarine }
